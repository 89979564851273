import { useTransition, a } from "react-spring";
import PropTypes from "prop-types"
import React, { useState } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import useBreakpoint from "../components/useBreakpoint";
import SlideNav from "../components/slide-nav"
import SocialNav from "./social-nav"
import CrisisLinks from "./crisis-links"
import Viacom from "../images/viacomcbs.svg"
import AdCouncil from "../images/ad_council.svg";

import "./header.scss"

const Header = ({ siteTitle }) => {
  let mobile = useBreakpoint() === "mobile"
  const [open,setOpen] = useState(false)

  const t = useTransition(open, null, {
    from: { transform: `translateX(100%)` },
    enter: { transform: `translateX(0)` },
    leave: { transform: `translateX(100%)` }
  });

  return <header className={`header ${open ? "open" : ""}`} onBlur={()=>{
    setOpen(false)
  }}>
    <h1 style={{display: "none"}}>{siteTitle}</h1>

    <button className="toggle-nav" tabIndex="0" onClick={() => {
      setOpen(!open)
    }}></button>

    {t.map(({item, key, props}) => (item &&  <a.div className="header-nav" style={props} key={key}>
      {mobile ? <SlideNav setOpen={setOpen} /> : null}

      <div className="CrisisLinks">
        <CrisisLinks />
        <p>For up-to-date info on the coronavirus, <OutboundLink href="https://coronavirus.gov" target="_blank" rel="noopener noreferrer" title="">visit coronavirus.gov</OutboundLink></p>
      </div>

      <ul>
        <li>
          <OutboundLink href="http://www.mtv.com/legal/terms" target="_blank" rel="noopener noreferrer" title="Go to mtv terms & conditions">
            Terms of Use
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="http://www.mtv.com/legal/privacy" target="_blank" rel="noopener noreferrer" title="Go to mtv privacy policy">
            Privacy Policy
          </OutboundLink>
        </li>
      </ul>
      {mobile ? <SocialNav /> : null}

      <div>
        <p style={{
          marginBottom: `1rem`
        }}>Powered by:</p>
        <ul style={{
          display: `flex`,
          justifyContent: `flex-start`,
          alignItems: `flex-start`
        }}>
          <li className="svg-link">
            <OutboundLink href="https://www.viacbs.com" target="_blank" rel="noopener noreferrer" title="go to ViacomCBS site">
              <Viacom />
            </OutboundLink>
          </li>
          <li className="svg-link">
            <OutboundLink href="https://www.adcouncil.org" target="_blank" rel="noopener noreferrer" title="go to AdCouncil site">
              <AdCouncil />
            </OutboundLink>
          </li>
        </ul>
      </div>
    </a.div>))}
  </header>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
