import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "./social-nav.scss"
import Facebook from "../images/MTV_facebook.svg";
import Instagram from "../images/MTV_instagram.svg";
import Twitter from "../images/MTV_twitter.svg"

const SocialNav = () => (
  <aside className="SocialNav">
    <ul>
      <li><OutboundLink href="https://www.instagram.com/explore/tags/alonetogether/" target="_blank" rel="noopener noreferrer" title=""><Instagram /></OutboundLink></li>
      <li><OutboundLink href="https://www.facebook.com/search/top/?q=%23alonetogether&epa=SERP_TAB" target="_blank" rel="noopener noreferrer" title=""><Facebook /></OutboundLink></li>
      <li><OutboundLink href="https://twitter.com/hashtag/alonetogether?lang=en" target="_blank" rel="noopener noreferrer" title=""><Twitter /></OutboundLink></li>
    </ul>
  </aside>
)

export default SocialNav
