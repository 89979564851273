import React from "react"

const SlideNav = ({active, setOpen=()=>{}}) => (
  <ul>
    <li>
      <a
        className={active === "stay-home" ? "active" : ""}
        href="#stay-home"
        role="button"
        onClick={() => {
          setOpen(false)
        }}>
        Stay Covered
      </a>
    </li>
    <li>
      <a
        className={active === "holidays" ? "active" : ""}
        href="#holidays"
        role="button"
        onClick={() => {
          setOpen(false)
        }}>
        Stay Safe This Holiday
      </a>
    </li>
    <li>
      <a
        className={active === "stay-connected" ? "active" : ""}
        href="#stay-connected"
        role="button"
        onClick={() => {
          setOpen(false)
        }}>
        #BackToSchoolTogether
      </a>
    </li>
    <li>
      <a
        className={active === "stay-calm" ? "active" : ""}
        href="#stay-calm"
        role="button"
        onClick={() => {
          setOpen(false)
        }}>
        Stay Calm
      </a>
    </li>
    {

    // <li>
    //   <a
    //     className={active === "stay-active" ? "active" : ""}
    //     href="#stay-active"
    //     role="button"
    //     onClick={() => {
    //       setOpen(false)
    //     }}>
    //     Stay Active
    //   </a>
    // </li>
    }
    <li>
      <a
        className={active === "more-resources" ? "active" : ""}
        href="#more-resources"
        role="button"
        onClick={() => {
          setOpen(false)
        }}>
        Resources
      </a>
    </li>
  </ul>
)

export default SlideNav
