import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const CrisisLinks = () => (
  <React.Fragment>
    <p>US Residents text SHARE to 741741 for free, confidential crisis counseling and visit the <OutboundLink href="https://www.crisistextline.org" target="_blank" rel="noopener noreferrer" title="go to crisis website">Crisis Text Line</OutboundLink> for options outside the U.S.</p>
  </React.Fragment>
)

export default CrisisLinks

